<template>
  <div class='role_info flex-start'>
    <img v-if='!isMobile' :src='gameIcon' class='game_icon' alt=''>
    <div class='wrap'>

            <Title :name="$sys('account.roleInfo')"></Title>

            <div v-if="isMobile" class="save_desktop">
              <el-button type='text' class='btn_save' @click="saveDesktop">
                <div class="flex-center">
                  <span class='icon icon-save-mobile'></span>
                  <span class='lang_name'>{{ $t("sys.btn.saveDesktopMobile") }}</span>
                </div>
              </el-button>
            </div>

      <div class='info flex-between' v-if='isRole'>

        <img v-if='isMobile' :src='gameIcon' class='game_icon' alt=''>

        <div>
          <p>{{ $sys('account.server') }}：<span class='value'>{{ userInfo.gameZoneName }}</span></p>
          <p>{{ $sys('account.roleID') }}：<span class='value'>{{ userInfo.gameRoleCode }}</span></p>
          <p class='role-name'>{{ $sys('account.roleName') }}：<span class='value'>{{ userInfo.gameRoleName
            }}</span></p>
        </div>
        <a href='javascript:;' class='btn' @click='handleChangeRole'>{{ $sys('account.changeRole') }}</a>
      </div>

      <div class='welcome flex-start' v-else>

        <img v-if='isMobile' :src='gameIcon' class='game_icon' alt=''>

        <div class='msg'>
          <p>{{ $sys('account.welcome') }}</p>
          <a href='javascript:;' class='btn' @click="login('sign_up')">{{ $sys('btn.login') }}</a>
        </div>
      </div>
    </div>

    <MobilePopRoleSelect lazy-request :visible.sync='isPopRole' @close='close()' />

  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Title from './Title'
import MobilePopRoleSelect from '@/components/MobilePopRoleSelect'
import { getGameIconByUrl, getGameNameByUrl } from '@/utils/game'
import { logLoginChange } from '@/utils/log'
import { Button } from 'element-ui'

export default {
    components: {
        [Button.name]: Button,
        Title,
        MobilePopRoleSelect
    },
    data() {
        return {
            isPopRole: false,
            noPermission: false,
            gameIcon: getGameIconByUrl()
        }
    },
    provide() {
      return {
        handleNoPermission: this.handleNoPermission
      }
    },
    computed: {
        ...mapState('user', ['userInfo']),
        ...mapGetters(['isRole', 'isMobile'])
    },
    methods: {
      ...mapActions('user', ['login', 'saveDesktop']),
      handleChangeRole() {
        this.isPopRole = true

        const gameName = getGameNameByUrl()
        const urlPath = this.$route.path

        if (urlPath === `/${gameName}/index`) {
          logLoginChange('charge')
        }
        if (urlPath === `/${gameName}/record`) {
          logLoginChange('record')
        }
      },
      handleNoPermission(value) {
        this.noPermission = value
      }
    }
}
</script>

<style lang='scss' scoped></style>